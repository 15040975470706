import React, { useContext } from 'react'
import styled from 'styled-components'
import { SvgClose } from 'components/common/icons/SvgIcons'
import { theme } from '../../../../theme'
import { TicketContext } from './ticketContext/TicketContext'
import { addDecimals } from '../../../../util/Utils'

const Container = styled.div`
	display: flex;
	align-items: center;

	justify-content: flex-end;
	margin-right: ${theme.spacing.md};
`

const FeeText = styled.p`
	font-size: ${theme.fontSizes.xs};
	width: 200px;

	text-align: right;
`

const PriceText = styled.p`
	font-size: ${theme.fontSizes.xl};
	font-weight: ${theme.fontWeight.bold};

	width: 140px;

	text-align: center;
`

const PopUpHeaderClose = styled.div`
	font-size: ${theme.fontSizes.md};
	height: 100%;
	cursor: pointer;

	position: absolute;
	top: ${theme.spacing.sm};
	right: ${theme.spacing.sm};
`

const SelectSeatPriceInfo = ({ closePopup }) => {
	const ticketContext = useContext(TicketContext)

	const { facilityFeePrice, convenienceFee, price, convenienceFeeBO } =
		ticketContext.ticketForPricing || {
			facilityFeePrice: 0,
			convenienceFee: 0,
			convenienceFeeBO: 0,
			price: 0,
		}

	const renderTicketDescription = () => {
		const validFacilityFee = Math.max(0, facilityFeePrice) // Ensure fee is non-negative
		const validConvenienceFee = Math.max(0, convenienceFeeBO) // Same for convenience fee


		let fees = []

		if (validConvenienceFee > 0) {
			fees.push(`+ $${addDecimals(validConvenienceFee)} convenience fee`)
		}

		return fees.length > 0
			? fees.join('')
			: 'No additional fees'
	}

	const renderTicketPrice = () => {
		return addDecimals(price)
	}

	return (
		<Container>
			<FeeText>{renderTicketDescription()}</FeeText>
			<PriceText>${renderTicketPrice()}</PriceText>
			<PopUpHeaderClose onClick={closePopup}>
				<SvgClose />
			</PopUpHeaderClose>
		</Container>
	)
}

export default SelectSeatPriceInfo
